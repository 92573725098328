import { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '@/components/LanguageSelector'
import { buildAssetsPath } from '@/libs/helpers/assets'
import { Divider, Grid, SvgIcon, Text } from '@/tbui'

const StyledFooter = styled.footer`
  background: ${(props) => props.theme.palette.GRAY_1};
  display: grid;
  grid-area: footer;
`

const StyledLogo = styled.img`
  height: auto;
  width: 140px;
`

const StyledAnchor = styled.a`
  text-decoration: none;
`

const FooterLink: FunctionComponent<{
  icon?: ReactNode
  text?: string
  href: string
}> = ({ icon, text, href }) => {
  const renderLinkContent = (): ReactNode => {
    if (text) {
      return <Text>{text}</Text>
    }
    /* istanbul ignore next */
    if (icon) {
      return icon
    }
    /* istanbul ignore next */
    return null
  }

  return (
    <StyledAnchor target="_blank" href={href}>
      {renderLinkContent()}
    </StyledAnchor>
  )
}

const Footer: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <StyledFooter>
      <Grid container p="40px 24px" gap="40px">
        <Grid xs={2} sm={3} md={4}>
          <Grid gap="24px">
            <Text type="H4" m="15px 0">
              {t('tbdine.footer.left.title')}
            </Text>
            <FooterLink
              href={t('tbdine.footer.left.link_about.href')}
              text={t('tbdine.footer.left.link_about.text')}
            />
            <FooterLink
              href={t('tbdine.footer.left.link_customers.href')}
              text={t('tbdine.footer.left.link_customers.text')}
            />
            <FooterLink
              href={t('tbdine.footer.left.link_press.href')}
              text={t('tbdine.footer.left.link_press.text')}
            />
            <FooterLink
              href={t('tbdine.footer.left.link_career.href')}
              text={t('tbdine.footer.left.link_career.text')}
            />
          </Grid>
          <Grid gap="24px">
            <Text type="H4" m="15px 0">
              {t('tbdine.footer.right.title')}
            </Text>
            <FooterLink
              href={t('tbdine.footer.right.link_privacy.href')}
              text={t('tbdine.footer.right.link_privacy.text')}
            />
            <FooterLink
              href={t('tbdine.footer.right.link_terms.href')}
              text={t('tbdine.footer.right.link_terms.text')}
            />
            <FooterLink
              href={t('tbdine.footer.right.link_disclosure.href')}
              text={t('tbdine.footer.right.link_disclosure.text')}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid sm={2} justify="stretch" gap="24px">
          <Grid justify="start" template="repeat(5, auto)" gap="4px">
            <StyledLogo src={buildAssetsPath('/images/tb-dine-logo-grey.png')} alt="logo" />
            <FooterLink
              icon={<SvgIcon name="twitter" fontSize="24px" color="GRAY_3" />}
              href="https://www.twitter.com/touchbistro/"
            />
            <FooterLink
              icon={<SvgIcon name="facebook" fontSize="24px" color="GRAY_3" />}
              href="https://www.facebook.com/TouchBistroiPadPOS/"
            />
            <FooterLink
              icon={<SvgIcon name="instagram" fontSize="24px" color="GRAY_3" />}
              href="https://www.instagram.com/touch_bistro/"
            />
            <FooterLink
              icon={<SvgIcon name="youtube" fontSize="24px" color="GRAY_3" />}
              href="https://www.youtube.com/user/touchbistro"
            />
          </Grid>
          <Grid justify="end" template="auto">
            <LanguageSelector />
          </Grid>
        </Grid>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
