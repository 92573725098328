import { FunctionComponent, memo } from 'react'
import { css, SerializedStyles, Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useMenuItem } from '@/components/EditItemModal/context'
import type { OnlineOperationalMenuItem } from '@/libs/helpers/adapters'
import { CroppedImage, Grid, gridDefaults } from '@/tbui'
import { textStyles } from '@/tbui/Text/styles'

const StyledGrid = styled.div`
  ${gridDefaults}
  padding: 0px 10px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.GRAY_1};
  @media (min-width: ${({ theme }) => theme.breakpoints.SM}) {
    :nth-of-type(1),
    :nth-of-type(2) {
      padding-top: 20px;
    }
  }
  align-content: space-between;
  gap: 10px;
  padding-bottom: 10px;
  height: 100%;
`

const nameStyles = (theme: Theme): SerializedStyles => css`
  ${textStyles(theme).heading.four}
  color: ${theme.palette.PRIMARY};
  word-break: break-word;
`

const nameWithStrikeThroughStyles = (theme: Theme): SerializedStyles => css`
  ${nameStyles(theme)}
  text-decoration: line-through;
`

const descriptionStyles = (theme: Theme): SerializedStyles => css`
  ${textStyles(theme).text.p}
  word-break: break-word;
`

const MenuItemComponent: FunctionComponent<{
  operationalMenuItem: OnlineOperationalMenuItem
  menuGroupXRefID: string
}> = ({ operationalMenuItem, menuGroupXRefID }) => {
  const theme = useTheme()
  const { setMenuItem } = useMenuItem()
  const { t } = useTranslation()

  const handleClick = (): void => {
    setMenuItem({ ...operationalMenuItem, menuGroupXRefID })
  }

  return (
    <StyledGrid
      data-test={`menu-item-tile-${operationalMenuItem.XRefID}`}
      onClick={handleClick}
      role="button"
    >
      <Grid
        template="auto 1fr"
        gap="10px"
        css={css`
          @media (min-width: ${theme.breakpoints.SM}) and (max-width: ${theme.breakpoints.MD}) {
            grid-row-gap: 10px;
            grid-column-gap: 0px;
            .text {
              grid-row-start: 2;
            }
          }
        `}
      >
        {operationalMenuItem.image != null && (
          <CroppedImage
            data-test={`item-image-${operationalMenuItem.XRefID}`}
            imageSrc={operationalMenuItem.image}
            height="87px"
            width="116px"
            radius="true"
          />
        )}
        <Grid gap="10px" className="text">
          <h4
            css={
              operationalMenuItem.isOutOfStock
                ? nameWithStrikeThroughStyles(theme)
                : nameStyles(theme)
            }
          >
            {operationalMenuItem.label}
          </h4>
          <p css={descriptionStyles(theme)}>{operationalMenuItem.description}</p>
        </Grid>
      </Grid>
      <Grid template="repeat(2, auto)" justify="space-between" m="4% 0 1% 0">
        <p css={textStyles(theme).heading.p}>
          {t('currency', { value: operationalMenuItem.price })}
        </p>
      </Grid>
    </StyledGrid>
  )
}

export default memo(MenuItemComponent)
