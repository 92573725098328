import { FunctionComponent, memo } from 'react'
import styled from '@emotion/styled'
import Image from 'next/image'
import { Grid } from '@/tbui'

const StyledBannerWrapper = styled(Grid)`
  grid-row: 2;
  grid-area: banner;
  max-width: 1280px;
  margin: 0 auto;
`

interface VenueBannerProps {
  venueBannerUrl: string
}

const VenueBanner: FunctionComponent<VenueBannerProps> = ({ venueBannerUrl }) => {
  return (
    <StyledBannerWrapper data-test="venue-branding-banner">
      <Image
        src={venueBannerUrl}
        width="1440px"
        height="320px"
        layout="responsive"
        priority
        objectFit="cover"
        unoptimized
        alt=""
      />
    </StyledBannerWrapper>
  )
}

export default memo(VenueBanner)
