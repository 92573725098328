import { FunctionComponent, ReactNode, useState } from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import Big from 'big.js'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { ChargeType, Delivery, Venue } from '@/libs/helpers/adapters'
import { Bill, Cart, ServiceChargeType, VenueLoyalty } from '@/libs/helpers/apiClient'
import { useWindowSize } from '@/libs/helpers/hooks/useWindowSize'
import { DeliveryFleet, OrderMethod, getOrderMethodSettings } from '@/libs/helpers/utils'
import { Grid, Modal, Overlay, Text } from '@/tbui'
import DGCBanner from './DGCBanner'
import { LoyaltyRewards } from './LoyaltyRewards'
import VenueInfo from './VenueInfo'

const StyledWrapper = styled(Grid)<{ hasLogoUrl?: boolean; hasBannerUrl?: boolean }>`
  ${(props) =>
    props.theme.mixin.fluidRange({
      prop: 'paddingTop',
      fromSize: '20px',
      toSize: '30px',
    })};
  padding-bottom: 24px;
  // coodinated with menu grid area padding
  padding-left: 24px;
  padding-right: 34px;
  margin-top: ${(props) => (props.hasLogoUrl && !props.hasBannerUrl ? '100px' : 0)};
  grid-column-gap: 20px;
  grid-template-areas: ${(props) =>
    props.hasLogoUrl
      ? "'logo info info info info' 'giftCard giftCard giftCard giftCard giftCard'"
      : "'info info info info info' 'giftCard giftCard giftCard giftCard giftCard'"};
  @media (max-width: ${(props) => props.theme.breakpoints.MD}) {
    padding-right: 24px;
    padding-bottom: 20px;
    margin-top: ${(props) => (props.hasLogoUrl && !props.hasBannerUrl ? '60px' : 0)};
    grid-template-areas: ${(props) =>
      props.hasLogoUrl
        ? "'logo logo logo logo logo' 'info info info info info' 'giftCard giftCard giftCard giftCard giftCard'"
        : "'info info info info info' 'giftCard giftCard giftCard giftCard giftCard'"};
  }
`

const StyledTitle = styled(Text)`
  ${(props) =>
    props.theme.mixin.fluidRange({
      prop: 'fontSize',
      fromSize: '24px',
      toSize: '36px',
    })};
`

const StyledRow = styled(Grid)<{ isScheduled?: boolean }>`
  grid-template-columns: repeat(5, auto);
  justify-content: start;
  gap: 12px;
`

const StyledVenueInfoWrapper = styled(Grid)`
  grid-area: info;
`

const StyledColumn = styled(Grid)`
  grid-area: logo;
  max-width: 200px;
  min-width: 150px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: -40%;
  @media (max-width: ${(props) => props.theme.breakpoints.MD}) {
    width: 130px;
    min-width: 130px;
    margin-top: -60px;
    margin-bottom: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    width: 85px;
    min-width: 85px;
    margin-top: -60px;
    margin-bottom: 1rem;
`

const StyledDeliveryInfoRow = styled(Grid)<{ isScheduled?: boolean }>`
  grid-template-columns: repeat(5, auto);
  justify-content: start;
  gap: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    & > *:last-child {
      flex-basis: 100%;
    }
  }
`

const DesktopOnlyText = styled(Text)<{ color?: string }>`
  color: ${(props) => props.color ?? props.theme.palette.GRAY_2};
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    display: none;
  }
`

const StyledTextDisabled = styled(Text)<{ color?: string }>`
  color: ${(props) => props.color ?? props.theme.palette.GRAY_2};
`

const StyledDeliveryGrid = styled.div`
  display: inline;
`

const StyledDeliveryFeeText = styled(Text)`
  display: inline;
`

const StyledMoreInfoText = styled(Text)`
  text-decoration: underline;
  font-size: 14px;
`

const StyledDeliveryFeeGrid = styled.div`
  display: flex;
  gap: 12px;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    gap: 8px;
  }
`

const StyledDGCRow = styled(Grid)`
  grid-area: giftCard;
`

export interface StyledVenueInfoAreaProps {
  open: boolean
  offset: number
}

const StyledVenueInfoArea = styled(Modal)<StyledVenueInfoAreaProps>``

interface VenueSummaryProps {
  venue: {
    address: Venue['address']
    name: Venue['name']
    phone: Venue['phone']
    venueOrderingMethods: Venue['venueOrderingMethods']
    branding: {
      logoUrl: Venue['branding']['logoUrl']
      bannerUrl: Venue['branding']['bannerUrl']
    }
    venueXRefID: string
  }
  cart: {
    isCartLoading: boolean
    scheduledFor?: Cart['scheduledFor']
    delivery?: Delivery
    bill: {
      serviceCharges: Bill['serviceCharges']
    }
    orderMethod: OrderMethod
  }
  availability: {
    isASAPAvailable: boolean
    isSchedulingAvailable: boolean
    venueReopensAt: string | null
  }
  loyaltyConfig?: VenueLoyalty
  isSignedIn?: boolean
}

const VenueSummary: FunctionComponent<VenueSummaryProps> = ({
  venue,
  cart,
  loyaltyConfig,
  availability: { isASAPAvailable, isSchedulingAvailable, venueReopensAt },
  isSignedIn = false,
}) => {
  // HOOKS
  const { t } = useTranslation()
  const theme = useTheme()
  const { width } = useWindowSize()
  // STATES
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  // CONSTS
  const orderMethod = cart.orderMethod
  const isAvailable =
    (isASAPAvailable && cart.scheduledFor == null) ||
    (isSchedulingAvailable && cart.scheduledFor != null)
  const isDelivery = orderMethod === OrderMethod.delivery
  const isScheduledOrder = !cart.isCartLoading && cart.scheduledFor != null && isSchedulingAvailable
  const hasActiveRewards =
    loyaltyConfig?.config != null && loyaltyConfig?.config?.rewards.length > 0

  const hasDDIntegration = cart.delivery?.fleet === DeliveryFleet.DOORDASH

  // we're only showing the banner if gift cards are enabled && TBPayments is enabled for the CWA
  const showDGCBanner =
    loyaltyConfig?.config?.giftCardsEnabled && loyaltyConfig?.config?.tbPaymentsRestaurantId != null
  const modalOffset = 77
  const isSmallScreen = width < Number(theme.breakpoints.SM.replace('px', ''))

  // HANDLERS
  const handleCloseModal = (): void => {
    setModalOpen(false)
  }

  const handleOpenModal = (): void => {
    setModalOpen(true)
  }

  const { waitTime } = getOrderMethodSettings(
    venue.venueOrderingMethods,
    orderMethod,
    cart.delivery
  )

  // RENDERERS
  const renderWaitTime = (): ReactNode => {
    if (!isAvailable) {
      if (venueReopensAt != null) {
        return `${t('ordering_not_available_until', {
          value: venueReopensAt,
        })}`
      }
      return t('closed_message')
    }

    if (waitTime <= 0) {
      return null
    }

    return t(
      'venue.summary.waitTime',
      !hasDDIntegration
        ? {
            context:
              isSmallScreen && orderMethod === OrderMethod.delivery
                ? 'mobile'
                : orderMethod.toLowerCase(),
            // count keyword must be used for plurals: https://www.i18next.com/translation-function/plurals
            count: waitTime,
          }
        : {
            context:
              isSmallScreen && orderMethod === OrderMethod.delivery
                ? 'mobile_plus_15_minutes'
                : `${orderMethod.toLowerCase()}_plus_15_minutes`,
            initialWaitTime: waitTime,
            waitTimePlus15mins: waitTime + 15,
          }
    )
  }

  // eslint-disable-next-line complexity
  const renderSubheader = (): ReactNode => {
    const hasMinSubtotal =
      cart.delivery != null &&
      cart.delivery.minSubtotal != null &&
      Big(cart.delivery.minSubtotal).gt(0)

    const deliveryServiceCharge = cart.bill.serviceCharges?.find(
      (serviceCharge) => serviceCharge.type === ServiceChargeType.DELIVERY_FEE
    )

    const hasDeliveryFee = cart.delivery != null && Big(cart.delivery.charge.amount).gt(0)

    // For DD if delivery fee is waived by provider we send back
    // isWaived true and exemptionThreshold as null
    const isProviderFeeWaived =
      hasDDIntegration &&
      cart.delivery?.exemptionThreshold == null &&
      deliveryServiceCharge?.isWaived

    return (
      <>
        <StyledRow data-test="venue-info">
          <Text>{venue.address}</Text>
          <Text color="GRAY_2">•</Text>
          <StyledMoreInfoText
            type="H3"
            data-test="venue-more-info"
            onClick={handleOpenModal}
            color="PRIMARY"
          >
            {t('venue.summary.more_info')}
          </StyledMoreInfoText>
        </StyledRow>
        <StyledDeliveryInfoRow isScheduled={isScheduledOrder}>
          {isScheduledOrder || waitTime === 0 ? null : (
            <Text color="GRAY_4" data-test="venue-wait-time">
              {renderWaitTime()}
            </Text>
          )}
          {isDelivery && cart.delivery != null && (
            <>
              {(Big(cart.delivery.charge.amount).gt(0) ||
                hasMinSubtotal ||
                deliveryServiceCharge != null) && (
                <>
                  {cart.scheduledFor == null && waitTime > 0 ? (
                    <StyledTextDisabled data-test="first-delivery-dot">•</StyledTextDisabled>
                  ) : null}
                  <StyledDeliveryGrid data-test="venue-delivery-fee">
                    {hasDeliveryFee && !isProviderFeeWaived ? (
                      <StyledDeliveryFeeText color="GRAY_4">
                        {t(
                          `venue.summary.delivery_fee_${cart.delivery.charge.type.toLowerCase()}`,
                          {
                            value:
                              cart.delivery.charge.type === ChargeType.DOLLAR
                                ? cart.delivery.charge.amount
                                : Big(cart.delivery.charge.amount).times(100).toString(),
                            context: isSmallScreen ? 'mobile' : '',
                          }
                        )}
                      </StyledDeliveryFeeText>
                    ) : (
                      deliveryServiceCharge != null && (
                        <StyledDeliveryFeeText color="GRAY_4">
                          {t('venue.summary.delivery_fee_free')}
                        </StyledDeliveryFeeText>
                      )
                    )}
                  </StyledDeliveryGrid>
                </>
              )}
              <StyledDeliveryFeeGrid>
                {hasMinSubtotal && (
                  <>
                    <DesktopOnlyText color="GRAY_2">•</DesktopOnlyText>
                    <Text color="GRAY_4" data-test="venue-min-subtotal">
                      {t('venue.summary.delivery_min_subtotal', {
                        value: cart.delivery.minSubtotal,
                      })}
                    </Text>
                  </>
                )}
                {hasDeliveryFee &&
                  cart.delivery.exemptionThreshold != null &&
                  Big(cart.delivery.exemptionThreshold).gt(0) && (
                    <>
                      <Text color="GRAY_2" data-test="second-delivery-dot">
                        •
                      </Text>
                      <Text color="GRAY_4" data-test="venue-delivery-exemption">
                        {t('venue.summary.delivery_exemption', {
                          value: cart.delivery.exemptionThreshold ?? 0,
                          context: isSmallScreen ? 'mobile' : '',
                        })}
                      </Text>
                    </>
                  )}
              </StyledDeliveryFeeGrid>
            </>
          )}
        </StyledDeliveryInfoRow>
        <StyledRow>
          {hasActiveRewards && (
            <LoyaltyRewards venueName={venue.name} rewards={loyaltyConfig?.config?.rewards} />
          )}
        </StyledRow>
      </>
    )
  }

  return (
    <StyledWrapper hasLogoUrl={!!venue.branding.logoUrl} hasBannerUrl={!!venue.branding.bannerUrl}>
      <Overlay open={modalOpen} onClick={handleCloseModal} />
      {venue.branding.logoUrl && (
        <StyledColumn data-test="venue-branding-logo">
          <Image
            src={venue.branding.logoUrl}
            width="200px"
            height="200px"
            layout="responsive"
            priority
            objectFit="cover"
            unoptimized
            style={{ borderRadius: '8px' }}
            alt=""
          />
        </StyledColumn>
      )}
      <StyledVenueInfoWrapper gap="10px">
        <Grid
          template="repeat(2, auto)"
          data-test="venue-name"
          justify="start"
          align="center"
          gap="5px"
        >
          <StyledTitle type="H3">{venue.name}</StyledTitle>
        </Grid>
        {renderSubheader()}
      </StyledVenueInfoWrapper>
      <StyledDGCRow>
        {showDGCBanner && loyaltyConfig?.config != null && (
          <DGCBanner
            venueName={venue.name}
            loyaltyRestaurantGroupID={loyaltyConfig?.config.tableUpGroupId}
            isSignedIn={isSignedIn}
          />
        )}
      </StyledDGCRow>
      <StyledVenueInfoArea
        data-test="venue-info-modal"
        onClose={handleCloseModal}
        offset={modalOffset}
        place-self="center"
        open={modalOpen}
      >
        <VenueInfo venue={venue} orderMethod={orderMethod} />
      </StyledVenueInfoArea>
    </StyledWrapper>
  )
}

export default VenueSummary
