import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Venue, WeekDay } from '@/libs/helpers/adapters'
import { formatPhone, formatTime } from '@/libs/helpers/formatters'
import { OrderMethod, getOrderMethodSettings } from '@/libs/helpers/utils'
import { useUserLanguage } from '@/providers/userLanguage'
import { Grid, Text } from '@/tbui'

const StyledRedesignedLink = styled.a`
  line-height: 1.29;
  color: ${(props) => props.theme.palette.PRIMARY};
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    font-size: 14px;
  }
`

const StyledSubtitle = styled(Text)`
  width: 98px;
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: ${(props) => props.theme.palette.TEXT_2};
  padding: 0px 0px 20px 0px;
  white-space: nowrap;
  ::last-child {
    padding: 0px 0px 500px 0px;
  }
`

const StyledRedesignedDayOfTheWeek = styled(Text)`
  line-height: 1.43;
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    font-size: 14px;
  }
`

const StyledRow = styled.div`
  margin: 24px 0 0 0;
`

const StyledText = styled(Text)`
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    font-size: 14px;
  }
`

interface VenueInfoProps {
  venue: {
    name: Venue['name']
    address: Venue['address']
    phone: Venue['phone']
    venueOrderingMethods: Venue['venueOrderingMethods']
  }
  orderMethod: OrderMethod
}

const VenueInfo: FunctionComponent<VenueInfoProps> = ({ venue, orderMethod }) => {
  const { name, address, phone } = venue
  const { t } = useTranslation()
  const { userLanguage } = useUserLanguage()
  const { weeklySchedule } = getOrderMethodSettings(venue.venueOrderingMethods, orderMethod)

  /**
   * formats weekly schedule as intervals
   * @param {WeekDay[]} weekDays - list of days of week of a schedule
   * @returns {string} if a restaurant is open from monday to friday, it would produce Monday-Friday
   */
  const formatTimeslotDays = (weekDays: WeekDay[]): string => {
    const weekDayMap: { [key: string]: number } = {
      [WeekDay.MON]: 1,
      [WeekDay.TUE]: 2,
      [WeekDay.WED]: 3,
      [WeekDay.THU]: 4,
      [WeekDay.FRI]: 5,
      [WeekDay.SAT]: 6,
      [WeekDay.SUN]: 7,
    }
    const delimiter = ',\t'
    const result: string[] = []

    weekDays.forEach((weekDay, i) => {
      const isRangeStart = weekDayMap[weekDay] + 1 === weekDayMap[weekDays[i + 1]]
      const isRangeEnd = weekDayMap[weekDay] - 1 === weekDayMap[weekDays[i - 1]]
      const isLastDay = i === weekDays.length - 1
      if (isRangeStart && !isRangeEnd) {
        result.push(t('weekday', { context: weekDay.toLowerCase() }))
        if (!isLastDay) {
          result.push('–')
        }
      }

      if (!isRangeStart && isRangeEnd) {
        result.push(t('weekday', { context: weekDay.toLowerCase() }))
        if (!isLastDay) {
          result.push(delimiter)
        }
      }

      if (!isRangeStart && !isRangeEnd) {
        result.push(t('weekdays', { context: weekDay.toLowerCase() }))
        if (!isLastDay) {
          result.push(delimiter)
        }
      }
    })

    return result.join('')
  }

  const redesignedVenueInfo = (
    <>
      <Text type="H1">{name}</Text>
      <Grid>
        <StyledRow>
          <StyledSubtitle>{t('venue.address')}</StyledSubtitle>
          <StyledText fontSize="16px">{address}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubtitle>{t('venue.phone')}</StyledSubtitle>
          <StyledRedesignedLink href={`tel:${phone}`}>{formatPhone(phone)}</StyledRedesignedLink>
        </StyledRow>
        <StyledRow>
          <StyledSubtitle>{t('venue.hours')}</StyledSubtitle>
          {weeklySchedule.map((schedule, index) => (
            <Grid
              p="0 0 10px 0"
              data-test="venue-info-ordering-hours"
              key={`${JSON.stringify(schedule.weekDays)}-${JSON.stringify(schedule.timeslots)}`}
            >
              {' '}
              <StyledRedesignedDayOfTheWeek bold>
                {formatTimeslotDays(schedule.weekDays)}
              </StyledRedesignedDayOfTheWeek>
              {schedule.timeslots.map(({ startTime, endTime }) => (
                <StyledText
                  // eslint-disable-next-line react/no-array-index-key
                  key={`time-${index}`}
                >
                  {`${formatTime(startTime, userLanguage)} – ${formatTime(endTime, userLanguage)}`}
                </StyledText>
              ))}
            </Grid>
          ))}
        </StyledRow>
      </Grid>
    </>
  )

  return (
    <Grid align="center" template="1fr">
      {redesignedVenueInfo}
    </Grid>
  )
}

export default VenueInfo
