import { FunctionComponent, useState } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import type { OnlineOperationalMenuPage } from '@/libs/helpers/adapters'
import { getMenuPageTimeDisplay } from '@/libs/helpers/utils'
import { Divider, Text, SvgIcon } from '@/tbui'
import { ToggleButton, SlideUp } from '@/tbui/Drawer'
import TileSelect, { TileOption } from '../TileSelect'
import { MenuDrawerSelector } from './MenuDrawerSelector'

const menuPageSelectorContainer = css`
  overflow: hidden;
  margin-left: 25px;
`

const menuPageSelectorStyles = (theme: Theme): string => css`
  overflow: hidden;

  @media (max-width: ${theme.breakpoints.SM}) {
    display: none;
  }
`

const MenuPageTile = styled.label<{ single: boolean }>`
  min-height: 3rem;
  margin-right: 3%;
  min-width: 10%;
  max-width: 40%;
  word-break: break-all;
  cursor: ${(props) => (props.single ? 'auto' : 'pointer')};

  &.is-selected {
    border-bottom: 3px solid ${({ theme }) => theme.palette.PRIMARY};
  }
`

const MenuPageLabel = styled(Text)`
  text-align: left;
  color: ${({ theme }) => theme.palette.TEXT};

  &.is-selected {
    color: ${({ theme }) => theme.palette.PRIMARY};
  }
`
const MenuPageSubtitle = styled(Text)`
  margin: 4% 0;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.TEXT_2};
`

const dividerStyles = css`
  height: 3px;
  margin-bottom: 12px;
  margin-top: -3px;
`

const toggleButtonLabelWrapper = css`
  min-height: 3rem;
`

interface MenuDrawerSelectorProps {
  locale: string
  menuPages: OnlineOperationalMenuPage[]
  selectedMenuPageID: string | null
  setSelectedMenuPage: (menuPageID: string) => void
}

export const MenuPageSelector: FunctionComponent<MenuDrawerSelectorProps> = ({
  locale,
  menuPages,
  selectedMenuPageID,
  setSelectedMenuPage,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false)
  const selectedMenu = menuPages.find((menu) => menu.XRefID === selectedMenuPageID)

  const renderMenuOptions = (menuPage: OnlineOperationalMenuPage): TileOption => {
    return {
      value: menuPage.XRefID,
      label: menuPage.label,
      subtitle:
        // menu entities with an always schedule type are always available
        // no need to show the times that the menu page is available for
        { value: getMenuPageTimeDisplay(menuPage, locale) },
    }
  }

  const menuPagesOptions = menuPages.map(renderMenuOptions)

  if (menuPages.length === 1) {
    return (
      <div className={menuPageSelectorContainer}>
        <MenuPageTile single>
          <MenuPageLabel bold>{menuPages[0].label}</MenuPageLabel>
          {renderMenuOptions(menuPages[0]).subtitle?.value}
        </MenuPageTile>
        <Divider classNames={dividerStyles} />
      </div>
    )
  }

  return (
    <div className={menuPageSelectorContainer}>
      <TileSelect
        name="menu-page-selector"
        className={menuPageSelectorStyles(theme)}
        components={{
          Tile: MenuPageTile,
          Label: MenuPageLabel,
          Subtitle: MenuPageSubtitle,
        }}
        onChange={(menuPage) => {
          setSelectedMenuPage(menuPage)
        }}
        options={menuPagesOptions}
        value={selectedMenuPageID ?? menuPagesOptions[0].value}
      />
      {selectedMenu != null && (
        <>
          <ToggleButton
            key="menu-drawer-toggle-btn"
            open={toggleDrawer}
            setOpen={setToggleDrawer}
            renderButtonText={
              <div className={toggleButtonLabelWrapper} data-test="menu-drawer-toggle-btn">
                <MenuPageLabel bold className="is-selected">
                  {selectedMenu.label}
                </MenuPageLabel>
                <MenuPageSubtitle>
                  {renderMenuOptions(selectedMenu).subtitle?.value}
                </MenuPageSubtitle>
              </div>
            }
            renderTrailingIcon={<SvgIcon name="chevron" fontSize="15px" color="PRIMARY" />}
          />
          <SlideUp
            open={toggleDrawer}
            setOpen={setToggleDrawer}
            title={t('menu_drawer_selector.select_a_menu_heading')}
            name="menuPageSelectorDrawer"
            subtitle={null}
          >
            <MenuDrawerSelector
              options={menuPages.map((menuPage) => ({
                ...renderMenuOptions(menuPage),
                renderTrailingIcon: (
                  <SvgIcon
                    name="check_filled"
                    fontSize="24px"
                    color="TEXT_SECONDARY"
                    stroke="SECONDARY"
                  />
                ),
              }))}
              selectedMenuPageID={selectedMenuPageID}
              onChangeMenu={(menuPage) => {
                setSelectedMenuPage(menuPage)
                setToggleDrawer(false)
              }}
            />
          </SlideUp>
        </>
      )}
      <Divider classNames={dividerStyles} />
    </div>
  )
}
