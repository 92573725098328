/* eslint-disable no-restricted-properties */
import { useState, useEffect, RefObject } from 'react'

export const useButtonScrolling = (
  ref: RefObject<HTMLDivElement>
): {
  width: number
  scrollEnd: boolean
  scrollByAmount: (amount: number) => void
  handleScrollPosition: () => void
  scrollX: number
} => {
  const [scrollEnd, setScrollEnd] = useState<boolean>(true)
  const [scrollX, setScrollX] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const handleScrollPosition = (): void => {
    const buffer = 2
    if (ref.current != null) {
      setScrollX(ref.current.scrollLeft)
      const isScrollEnd =
        Math.floor(ref.current.scrollWidth - ref.current.scrollLeft - buffer) <=
        ref.current.offsetWidth
      setScrollEnd(!!isScrollEnd)
    }
  }
  const handleResize = (): void => {
    if (ref?.current) {
      setWidth(ref?.current?.offsetWidth)
    }
  }
  // scroll left w pos amount, right w neg amount
  const scrollByAmount = (amount: number): void => {
    ref.current?.scrollBy({
      top: 0,
      left: amount,
      behavior: 'smooth',
    })
    setScrollX(scrollX + amount)
    handleScrollPosition()
  }

  useEffect(() => {
    handleScrollPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.scrollWidth, ref?.current?.offsetWidth, ref])

  // handle scroll events
  useEffect(() => {
    let currentRef: HTMLDivElement
    if (ref?.current) {
      currentRef = ref.current
      currentRef.addEventListener('scroll', handleScrollPosition)
    }
    return () => currentRef.removeEventListener('scroll', handleScrollPosition)
  })

  useEffect(() => {
    if (ref?.current && !width) {
      setWidth(ref?.current.offsetWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    scrollEnd,
    scrollByAmount,
    handleScrollPosition,
    width,
    scrollX,
  }
}
