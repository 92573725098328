import { useState, FunctionComponent, useRef, useEffect } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  MenuSource,
  OnlineOperationalMenuGroup,
  OnlineOperationalMenuGroups,
  OnlineOperationalMenuItems,
  OnlineOperationalMenuPage,
} from '@/libs/helpers/adapters'
import { useDebounce } from '@/libs/helpers/hooks/useDebounce'
import { useWindowSize } from '@/libs/helpers/hooks/useWindowSize'
import { getMenuPageTimeDisplay } from '@/libs/helpers/utils'
import { Divider, SvgIcon, useClickOutsideEvent } from '@/tbui'
import { SlideUp, ToggleButton } from '@/tbui/Drawer'
import { textStyles } from '@/tbui/Text/styles'
import { MenuGroupDrawerSelector } from './MenuGroupDrawerSelector'
import { MenuGroupPopover } from './MenuGroupPopover'
import { TabCarousel } from './TabCarousel'
import { TabPanel } from './TabPanel'

const STICKY_MENU_GROUP_DESKTOP_HEIGHT = 65.5
const STICKY_MENU_GROUP_MOBILE_HEIGHT = 57.5

/** Menu Group Styles */
const menuGroupsBaseStyles = (theme: Theme): string => css`
  position: -webkit-sticky; // support for Safari
  position: sticky;
  top: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0 24px;
  z-index: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  @media (min-width: ${theme.breakpoints.MD}) {
    padding-right: 34px;
  }
  @media (max-width: ${theme.breakpoints.MD}) {
    border-bottom: none;
  }
  @media (max-width: ${theme.breakpoints.SM}) {
    span {
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        i {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  background: ${theme.palette.WHITE};
`

const tabListStyles = css`
  display: inline-block;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
`

const MenuGroupListButton = styled.button`
  border: none;
  background-color: transparent;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.SM}) {
    display: none;
  }
`

const menuGroupPopoverAnchorStyles = css`
  position: sticky;
  top: 0;
  z-index: 6;
`

const groupNameStyles = (theme: Theme): string => css`
  color: ${theme.palette.TEXT};
  font-size: 20px;
  font-family: ${theme.font.FAMILY_BOLD};
  line-height: 24px;
  @media (max-width: ${theme.breakpoints.SM}) {
    padding-bottom: 12px;
  }
`

const menuGroupElementStyles = css`
  padding-top: 16px;
  word-break: break-all;
`

const calorieStatementStyles = (theme: Theme): string => css`
  ${textStyles(theme).text.five}
  color: ${theme.palette.TEXT_2};
  padding-top: 1.5rem;
  text-align: center;
`

/** Tab Styles */
const tabPanelsStyles = (theme: Theme): string => css`
  padding-left: 24px;
  @media (max-width: ${theme.breakpoints.MD}) {
    padding-right: 24px;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 2px solid ${theme.palette.GRAY_1};
  }
`

const dividerStyles = (theme: Theme): string => css`
  margin: 12px 0;
  height: 2px;
  margin-bottom: 24px;
  @media (max-width: ${theme.breakpoints.MD}) {
    display: none;
  }
`

const slideUpBodyStyles = (theme: Theme): string => css`
  @media (max-width: ${theme.breakpoints.SM}) {
    padding: 25px 0 0 0;
  }
`

interface MenuGroupProps {
  locale: string
  selectedMenuGroupID: string
  onMenuGroupSelect: (menuGroupID: string) => void
  menuSource: MenuSource
  operationalMenuGroupsList: OnlineOperationalMenuGroup[]
  operationalMenuItems: OnlineOperationalMenuItems
  operationalMenuGroups: OnlineOperationalMenuGroups
  operationalMenuPage: OnlineOperationalMenuPage | null
  calorieStatement: string
}

const MenuGroupSelector: FunctionComponent<MenuGroupProps> = ({
  locale,
  operationalMenuItems,
  operationalMenuGroupsList,
  selectedMenuGroupID,
  onMenuGroupSelect,
  calorieStatement,
  menuSource,
  operationalMenuGroups,
  operationalMenuPage,
}) => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  const headerOffset =
    windowSize.width < theme.breakpointValues.SM
      ? STICKY_MENU_GROUP_MOBILE_HEIGHT
      : STICKY_MENU_GROUP_DESKTOP_HEIGHT
  const [showMenuGroupPopover, setShowMenuGroupPopover] = useState(false)
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false)

  const menuGroupPopoverRef = useRef<HTMLDivElement>(null)
  useClickOutsideEvent(menuGroupPopoverRef, () => setShowMenuGroupPopover(false))

  // this ref is to track what group was clicked on menu group tab, when vertical scrolling starts
  const scrolledGroup = useRef<string | null>(null)
  // we should make sure that after group is clicked, and page starts scrolling,
  // then that group ends up always being selected (i.e when it doesn't cover entire view height)
  const menuGroupRefs = useRef<Record<string, Element>>({})

  const updateGroupDebounce = useDebounce((groupID: string) => {
    onMenuGroupSelect(groupID)
    scrolledGroup.current = null
  }, 100)

  const handleSelected = (id: string): void => {
    // tab change is handled with a scroll event
    scrolledGroup.current = id
    const selectedMenuGroup = operationalMenuGroups[id]
    updateGroupDebounce(id)
    if (menuGroupRefs.current != null && menuGroupRefs.current[selectedMenuGroup.XRefID] != null) {
      const groupElement = menuGroupRefs.current[selectedMenuGroup.XRefID]
      // eslint-disable-next-line no-restricted-properties
      window.scrollTo({
        behavior: 'smooth',
        top:
          groupElement.getBoundingClientRect().top -
          // eslint-disable-next-line no-restricted-properties
          document.body.getBoundingClientRect().top -
          headerOffset,
      })
    }

    setShowMenuGroupPopover(false)
    setToggleDrawer(false)
  }

  useEffect(() => {
    const checkGroupVisibility = (): void => {
      if (menuGroupRefs.current == null) {
        return
      }

      for (const element of Object.values(menuGroupRefs.current)) {
        if (element == null) {
          continue
        }
        const rect = element.getBoundingClientRect()
        const top = rect.top
        const bottom = rect.bottom
        // eslint-disable-next-line no-restricted-properties
        const height = window.innerHeight
        const isVisible =
          // adding 0.5px to prevent "flickering" happening when menu groups scroll
          // to exact pixel where group above would be considered selected
          top < height && bottom - (headerOffset + 0.5) >= 0

        if (isVisible) {
          onMenuGroupSelect(element.id)
          break
        }
      }

      // if scroll happens because of menu group selection...
      if (scrolledGroup.current != null) {
        // fire a debounced function to set the selected group after scroll ends
        updateGroupDebounce(scrolledGroup.current)
      }
    }

    // eslint-disable-next-line no-restricted-properties
    window.addEventListener('scroll', checkGroupVisibility)

    return () => {
      // eslint-disable-next-line no-restricted-properties
      window.removeEventListener('scroll', checkGroupVisibility)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Mobile Menu Group View */}
      <SlideUp
        title={
          menuSource === 'RMM3' && operationalMenuPage !== null ? operationalMenuPage.label : null
        }
        subtitle={
          menuSource === 'RMM3' && operationalMenuPage !== null
            ? getMenuPageTimeDisplay(operationalMenuPage, locale)
            : null
        }
        open={toggleDrawer}
        setOpen={setToggleDrawer}
        name="menuGroupSelectorDrawer"
        classNames={slideUpBodyStyles(theme)}
      >
        <MenuGroupDrawerSelector
          selectedMenuGroupID={selectedMenuGroupID}
          onChangeMenuGroup={handleSelected}
          options={operationalMenuGroupsList.map(({ XRefID, label }) => ({
            value: XRefID,
            label,
            renderTrailingIcon: (
              <SvgIcon
                name="check_filled"
                fontSize="24px"
                color="TEXT_SECONDARY"
                stroke="SECONDARY"
              />
            ),
          }))}
        />
      </SlideUp>
      {/* Desktop Menu Group View */}
      {showMenuGroupPopover && (
        <div ref={menuGroupPopoverRef} className={menuGroupPopoverAnchorStyles}>
          <MenuGroupPopover
            operationalMenuGroups={operationalMenuGroupsList}
            selectedMenuGroupID={selectedMenuGroupID}
            handleSelected={handleSelected}
          />
        </div>
      )}
      <div className={menuGroupsBaseStyles(theme)}>
        <MenuGroupListButton
          onClick={(): void => {
            setShowMenuGroupPopover(!showMenuGroupPopover)
          }}
        >
          <SvgIcon name="hamburger" fontSize="24px" color="PRIMARY" />
        </MenuGroupListButton>
        <ToggleButton
          key="menu-group-drawer-toggle-btn"
          open={toggleDrawer}
          setOpen={(open) => {
            setToggleDrawer(open)
            setShowMenuGroupPopover(false)
          }}
          renderButtonText={<SvgIcon name="hamburger" fontSize="24px" color="PRIMARY" />}
        />
        <div className={tabListStyles} aria-label="menu" role="tablist">
          <TabCarousel
            operationalMenuGroups={operationalMenuGroupsList}
            handleSelected={handleSelected}
            selected={selectedMenuGroupID}
          />
        </div>
      </div>
      <section className={tabPanelsStyles(theme)}>
        <Divider classNames={dividerStyles(theme)} />
        {operationalMenuGroupsList.map((operationalMenuGroup) => (
          <div
            className={menuGroupElementStyles}
            ref={(el) => {
              if (el != null) {
                menuGroupRefs.current[operationalMenuGroup.XRefID] = el
              }
            }}
            id={operationalMenuGroup.XRefID}
            key={operationalMenuGroup.XRefID}
          >
            <div className={groupNameStyles(theme)}>{operationalMenuGroup.label}</div>
            <TabPanel
              operationalMenuGroup={operationalMenuGroup}
              operationalMenuItems={(operationalMenuGroup.menuItemRefs ?? []).map(
                (item) => operationalMenuItems[item.id]
              )}
            />
          </div>
        ))}
      </section>
      <p className={calorieStatementStyles(theme)} data-test="calorie-statement">
        {calorieStatement}
      </p>
    </>
  )
}

export { MenuGroupSelector }
