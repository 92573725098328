import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { OnlineOperationalMenuGroup, OnlineOperationalMenuItem } from '@/libs/helpers/adapters'
import { Grid } from '@/tbui'
import MenuItem from '../MenuItem'

const Panel = styled.section`
  display: grid;
  padding-top: 0px;
  gap: 20px;
  .items {
    @media (min-width: ${({ theme }) => theme.breakpoints.MD}) {
      gap: 20px 40px;
      width: 90%;
    }
  }
`

interface TabPanelProps {
  operationalMenuGroup: OnlineOperationalMenuGroup
  operationalMenuItems: OnlineOperationalMenuItem[]
}

export const TabPanel: FunctionComponent<TabPanelProps> = ({
  operationalMenuGroup,
  operationalMenuItems,
}) => (
  <Panel
    role="tabpanel"
    aria-label={operationalMenuGroup.label}
    id={`panel-${operationalMenuGroup.XRefID}`}
    tabIndex={0}
  >
    <Grid className="items" xs={1} sm={2} md={2} lg={2} gap="20px">
      {operationalMenuItems.map((item, i) => {
        const key = `${operationalMenuGroup.XRefID}-${item.XRefID}-${i}`
        return (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            operationalMenuItem={item}
            menuGroupXRefID={operationalMenuGroup.XRefID}
          />
        )
      })}
    </Grid>
  </Panel>
)
