import { FunctionComponent, useState } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import type { Delivery, OperationalMenu, VenueOrderingMethod } from '@/libs/helpers/adapters'
import type { Bill, Loyalty, VenueLoyalty } from '@/libs/helpers/apiClient'
import type { OrderItem, OrderMethod } from '@/libs/helpers/utils'
import { SlideUp, ToggleButton } from '@/tbui/Drawer'
import CartWidget, { CartWidgetVariant } from '.'

const slideUpBodyStyles = (theme: Theme): string => css`
  @media (max-width: ${theme.breakpoints.SM}) {
    padding: 0;
  }
`
interface CartDrawerProps {
  cart: {
    bill: Bill
    delivery?: Delivery
    isCartLoading: boolean
    scheduledFor?: string
    appliedLoyaltyRewardName?: string
    items: OrderItem[]
    orderMethod: OrderMethod
  }
  variant: CartWidgetVariant
  venue: {
    isASAPAvailable: boolean
    isSchedulingAvailable: boolean
    venueReopensAt: string | null
    name: string
    active: boolean
    venueOrderingMethods: VenueOrderingMethod[]
    venueXRefID: string
  }
  offersLoyaltyRewards: boolean
  onItemsChange: (items: OrderItem[]) => Promise<void>
  userRewards: Loyalty | null
  onClearCart: () => Promise<void>
  onClearLoyalty: () => void
  onCheckout: () => void
  loyaltyConfig: VenueLoyalty
  operationalMenu: OperationalMenu
}

const CartDrawer: FunctionComponent<CartDrawerProps> = ({
  cart: {
    bill,
    isCartLoading,
    items,
    delivery,
    scheduledFor,
    appliedLoyaltyRewardName,
    orderMethod,
  },
  variant,
  onItemsChange,
  venue,
  userRewards,
  onClearLoyalty,
  onClearCart,
  offersLoyaltyRewards,
  onCheckout,
  loyaltyConfig,
  operationalMenu,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  // a merchant can have ASAP unavailable but offer scheduling
  // therefore, ordering would still be available but only for a future time
  const isAvailable =
    (venue.isASAPAvailable && scheduledFor == null) ||
    (venue.isSchedulingAvailable && scheduledFor != null)

  return (
    <>
      <SlideUp
        classNames={slideUpBodyStyles(theme)}
        open={isDrawerOpen}
        setOpen={setIsDrawerOpen}
        showCloseButton
        showIndicator
        name="cartDrawer"
        title={null}
        subtitle={null}
        shouldCloseOnClickOutside={false}
      >
        <CartWidget
          cart={{
            bill,
            delivery,
            isCartLoading,
            scheduledFor,
            items,
            appliedLoyaltyRewardName,
            orderMethod,
          }}
          variant={variant}
          offersLoyaltyRewards={offersLoyaltyRewards}
          onItemsChange={async (cartItems: OrderItem[]) => {
            onItemsChange(cartItems)
          }}
          userRewards={userRewards}
          onClearCart={onClearCart}
          onClearLoyalty={onClearLoyalty}
          onSubmit={onCheckout}
          loyaltyConfig={loyaltyConfig}
          operationalMenu={operationalMenu}
          venue={venue}
        />
      </SlideUp>
      {/** Only display cart toggle button when there are items in the cart or when restaurant is closed */}
      {items.length > 0 || !isAvailable ? (
        <ToggleButton
          key="cart-widget-drawer-toggle-btn"
          className="cart-widget-drawer-toggle-btn"
          open={isDrawerOpen}
          setOpen={setIsDrawerOpen}
          isSticky
          disabled={!isAvailable}
          renderButtonText={
            isAvailable ? t('cart_widget.footer.button_view') : t('restaurant_closed')
          }
        />
      ) : null}
    </>
  )
}

export { CartDrawer }
