import { FunctionComponent, useState, useEffect } from 'react'
import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import type {
  OnlineOperationalMenuRefItem,
  OperationalMenu,
  MenuSource,
} from '@/libs/helpers/adapters'
import { useUserLanguage } from '@/providers/userLanguage'
import { textStyles } from '@/tbui/Text/styles'
import { MenuGroupSelector } from './MenuGroup/MenuGroupSelector'
import { MenuPageSelector } from './MenuPageSelector'

const noMenuStyles = css`
  display: grid;
  padding: 100px 24px;
  text-align: center;
`

interface MenuListProps {
  calorieStatement: string
  menuSource: MenuSource
  sortedMenuPages: OnlineOperationalMenuRefItem[]
  operationalMenu: OperationalMenu
}

const MenuList: FunctionComponent<MenuListProps> = ({
  calorieStatement,
  sortedMenuPages,
  operationalMenu,
  menuSource,
}) => {
  const theme = useTheme()
  const { userLanguage } = useUserLanguage()
  const { t } = useTranslation()
  const router = useRouter()
  const [selectedMenuPageID, setSelectedMenuPageID] = useState<string | null>(() => {
    const menuPageQuery = router.query.menuPage?.toLowerCase()
    if (!!menuPageQuery && menuPageQuery !== '') {
      const menuPage = Object.values(operationalMenu.menuPages).find(
        (menuPage) =>
          // removes all non-alphanumeric characters, and replace space with _
          menuPage.label
            .toLowerCase()
            .replaceAll(' ', '_')
            .replaceAll(/[^a-z\d_]/g, '') === menuPageQuery?.toLowerCase()
      )
      if (menuPage) {
        return menuPage.XRefID
      }
    }
    return sortedMenuPages[0]?.id ?? null
  })
  const selectedMenuPage =
    selectedMenuPageID != null ? operationalMenu.menuPages[selectedMenuPageID] ?? null : null
  const operationalMenuGroups =
    selectedMenuPage?.menuGroupRefs.map((group) => operationalMenu.menuGroups[group.id]) ?? []

  const initialSelectedMenuGroupIDState = (): string => {
    if (
      selectedMenuPage != null &&
      selectedMenuPage.menuGroupRefs != null &&
      selectedMenuPage.menuGroupRefs.length > 0
    ) {
      return selectedMenuPage.menuGroupRefs[0].id
    }
    return ''
  }

  const [selectedMenuGroupID, setSelectedMenuGroupID] = useState<string>(
    initialSelectedMenuGroupIDState()
  )

  // when schedule changes, we check if selected page is still available
  useEffect(() => {
    if (
      sortedMenuPages.length > 0 &&
      !Object.values(operationalMenu.menuPages).some(
        (menuPage) => menuPage.XRefID === selectedMenuPageID
      )
    ) {
      setSelectedMenuPageID(sortedMenuPages[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationalMenu])

  // if there was an error, pageProps default value for menu is []
  if (
    Object.values(operationalMenu.menuGroups).length === 0 ||
    sortedMenuPages.length === 0 ||
    Object.values(operationalMenu.menuPages).flatMap((menuPage) => menuPage.menuGroupRefs)
      .length === 0
  ) {
    return (
      <div className={noMenuStyles}>
        <h3 css={[textStyles(theme).heading.three, { margin: '15px 0' }]}>
          {t('no_menu_available')}
        </h3>
        <p css={textStyles(theme).text.p}>{t('empty_menu')}</p>
      </div>
    )
  }

  return (
    <>
      {menuSource === 'RMM3' && (
        <MenuPageSelector
          locale={userLanguage}
          setSelectedMenuPage={(menuPageID) => {
            setSelectedMenuPageID(menuPageID)
            // pre-select the first menu group
            setSelectedMenuGroupID(operationalMenu.menuPages[menuPageID].menuGroupRefs[0].id)
          }}
          selectedMenuPageID={selectedMenuPageID}
          menuPages={sortedMenuPages.map((menuPage) => operationalMenu.menuPages[menuPage.id])}
        />
      )}
      <MenuGroupSelector
        locale={userLanguage}
        menuSource={menuSource}
        operationalMenuPage={selectedMenuPage}
        operationalMenuGroupsList={operationalMenuGroups}
        operationalMenuGroups={operationalMenu.menuGroups}
        operationalMenuItems={operationalMenu.menuItems}
        selectedMenuGroupID={selectedMenuGroupID}
        onMenuGroupSelect={(menuGroupID: string): void => {
          setSelectedMenuGroupID(menuGroupID)
        }}
        calorieStatement={calorieStatement}
      />
    </>
  )
}

export default MenuList
