import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Text } from '@/tbui'
import TileSelect, { TileOption } from '../TileSelect'

const StyledMenuTitle = styled.label`
  border-radius: ${({ theme }) => theme.shape.RADIUS_ALT};
  display: grid;
  font-size: 16px;
  min-height: 4.5rem;
  color: ${({ theme }) => theme.palette.TEXT};
  font-family: ${({ theme }) => theme.font.FAMILY_BOLD};
  align-items: center;
  justify-items: start;
  grid-template-areas:
    'title icon'
    'subtitle icon';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &.is-selected {
    background-color: ${({ theme }) => theme.palette.SECONDARY};
  }
  margin: 5px 0;
  padding: 16px;
`

const StyledMenuLabel = styled.span`
  color: ${({ theme }) => theme.palette.TEXT};
  grid-area: title;
  vertical-align: middle;
  word-break: break-word;
  line-height: 16px;
  &.is-selected {
    color: ${({ theme }) => theme.palette.TEXT_SECONDARY};
  }
`

const StyledMenuSubtitle = styled(Text)`
  grid-area: subtitle;
  color: ${({ theme }) => theme.palette.TEXT};
  font-weight: 400;
  font-size: 14px;
  margin-top: 0.5rem;
  &.is-selected {
    color: ${({ theme }) => theme.palette.TEXT_SECONDARY};
  }
`

interface MenuDrawerSelectorProps {
  selectedMenuPageID: string | null
  onChangeMenu: (menuPageID: string) => void
  options: TileOption[]
}

const MenuDrawerSelector: FunctionComponent<MenuDrawerSelectorProps> = ({
  options,
  selectedMenuPageID,
  onChangeMenu,
}) => {
  return (
    <TileSelect
      name="menu-drawer-selector"
      components={{
        Tile: StyledMenuTitle,
        Label: StyledMenuLabel,
        Subtitle: StyledMenuSubtitle,
      }}
      onChange={onChangeMenu}
      stacked
      value={selectedMenuPageID ?? options[0].value}
      options={options}
    />
  )
}

export { MenuDrawerSelector }
