import { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import CartWidget, { CartWidgetVariant } from '@/components/CartWidget'
import { CartDrawer } from '@/components/CartWidget/CartDrawer'
import EditItemModal from '@/components/EditItemModal'
import EditItemProvider from '@/components/EditItemModal/context'
import Footer from '@/components/Footer'
import GuestCheckoutOptionsModal from '@/components/GuestCheckout/GuestCheckoutOptionsModal'
import Header from '@/components/Header'
import OrderMethodBanner from '@/components/Header/OrderMethodBanner'
import MenuList from '@/components/MenuList'
import OrderMethodSelector from '@/components/OrderMethodSelector'
import VenueBanner from '@/components/VenueBanner'
import VenueSummary from '@/components/VenueSummary'
import { MenuSource, OnlineOperationalMenuRefItem, OperationalMenu } from '@/libs/helpers/adapters'
import {
  CartUpdateRequest,
  GuestInformation,
  Loyalty,
  getOnlineOperationalMenu,
  updateMe,
} from '@/libs/helpers/apiClient'
import { composeCartError } from '@/libs/helpers/composeCartError'
import { StaleCartErrorCode } from '@/libs/helpers/errorMapper'
import { ClientErrorStatusCode, parseRequestError } from '@/libs/helpers/error_handler'
import { useCookies } from '@/libs/helpers/hooks/useCookies'
import { useDebounce } from '@/libs/helpers/hooks/useDebounce'
import { useWindowSize } from '@/libs/helpers/hooks/useWindowSize'
import {
  CartState,
  HeaderVariant,
  OrderItem,
  OrderMethod,
  constructGTMSession,
  getOrderMethodSettings,
  mapItemsToCartRequest,
} from '@/libs/helpers/utils'
import { SSRPage, withServerSideProps } from '@/libs/helpers/withServerSidePropsDecorator'
import { useConfiguration } from '@/providers/configuration/ConfigurationContext'
import { useError } from '@/providers/error'
import { useModal } from '@/providers/modal'
import { LoadScreen } from '@/tbui'
import DefaultThemeProvider from '@/tbui/DefaultThemeProvider'

const menuTemplateStyles = (theme: Theme): string => css`
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'header header header header'
    'banner banner banner banner'
    'main main main main'
    'footer footer footer footer';
  display: grid;
  width: 100%;

  @media (max-width: ${theme.breakpoints.MD}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'header'
      'orderMethod'
      'banner'
      'main'
      'footer';
  }
`

const menuPageBodyLayout = (theme: Theme): string => css`
  max-width: ${theme.shape.CONTAINER};
  display: grid;
  grid-area: main;
  margin: 0 auto;
  width: 100%;
  grid-template-areas:
    'top sidebar'
    'middle sidebar';
  grid-template-columns: 1fr 415px;
  grid-template-rows: auto 1fr auto;
  // 512px = Header + Footer
  min-height: calc(100vh - 512px);
  @media (max-width: ${theme.breakpoints.MD}) {
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'top top'
      'middle sidebar';
  }
  @media (max-width: ${theme.breakpoints.SM}) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    grid-template-areas:
      'top'
      'middle'
      'sidebar';
  }
`

// same as venue summary section for alignment of right side before cart
const venueSectionStyles = css`
  display: grid;
  grid-area: top;
`

const menuSectionStyles = css`
  padding-bottom: 24px;
  display: grid;
  grid-area: middle;
  grid-template-rows: repeat(3, auto) 1fr auto;
`

const cartWidgetSectionStyles = (theme: Theme): string => css`
  display: grid;
  grid-area: sidebar;
  @media (min-width: ${theme.breakpoints.SM}) and (max-width: ${theme.breakpoints.MD}) {
    box-shadow: unset;
    border-left: 2px solid ${theme.palette.GRAY_1};
  }
`

export type MenuPageProps = {
  sortedMenuPages: OnlineOperationalMenuRefItem[]
  operationalMenu: OperationalMenu
  menuSource: MenuSource
}

export const getServerSideProps = withServerSideProps<MenuPageProps>({
  onPageRequest: async ({ params }, { headers, initialCart }) => {
    const { orderMethod: paramOrderMethod, venueXRefID } = params
    const orderMethod = OrderMethod[paramOrderMethod]

    const menuProps: MenuPageProps = {
      operationalMenu: {
        menuPages: {},
        menuGroups: {},
        menuItems: {},
        modifierGroups: {},
        modifierOptions: {},
      },
      sortedMenuPages: [],
      menuSource: 'RMM2',
    }

    try {
      const menu = await getOnlineOperationalMenu(venueXRefID, {
        headers,
        params: {
          orderMethod,
          ...(initialCart?.scheduledFor != null ? { orderTime: initialCart.scheduledFor } : {}),
        },
      })

      menuProps.menuSource = menu.source
      menuProps.sortedMenuPages = menu.sortedMenuPages
      menuProps.operationalMenu = {
        menuPages: menu.refs.menuPages,
        menuGroups: menu.refs.menuGroups,
        menuItems: menu.refs.menuItems,
        modifierGroups: menu.refs.modifierGroups,
        modifierOptions: menu.refs.modifierOptions,
      }
    } catch (error) {
      if (parseRequestError(error).statusCode !== ClientErrorStatusCode.NOT_FOUND) {
        throw error
      }
    }

    return {
      props: menuProps,
    }
  },
})

const MenuPage: SSRPage<MenuPageProps> = ({
  globals: { venue, initialCart, loyalty, loyaltyConfig, deliveryAddresses, profile, guest },
  menuSource,
  sortedMenuPages,
  operationalMenu,
  error,
}) => {
  // HOOKS
  const theme = useTheme()
  const { userSession } = useConfiguration()
  const router = useRouter()
  const [cart, setCart] = useState<CartState>({
    ...initialCart,
    isCartLoading: false,
  })
  const orderMethod =
    initialCart.orderMethod ?? OrderMethod[router.query.orderMethod as keyof typeof OrderMethod]
  const { setModalError } = useError()
  const { closeModal, openModal } = useModal()
  const { setRedirectURLCookie } = useCookies()
  const windowSize = useWindowSize()
  // using this ref to capture the first render of the page
  const [userRewards, setUserRewards] = useState<Loyalty | null>(loyalty ?? null)

  // CONSTS
  const venueXRefID = venue?.venueXRefID as string

  const orderMethodSettings = getOrderMethodSettings(
    venue?.venueOrderingMethods ?? [],
    orderMethod,
    cart.delivery
  )
  const { waitTime, isSchedulingAvailable, isASAPAvailable, nextAvailable } = orderMethodSettings
  // nextAvailable is used for scheduling, which accounts for wait time
  // to calculate re-opening time, we must remove wait time from nextAvailable
  const nextAvailableMinusWaitTime =
    nextAvailable != null ? DateTime.fromISO(nextAvailable).minus({ minutes: waitTime }) : null

  // The API response gives us normalized timeslots, i.e. rounded up to the nearest 15 minutes
  // E.g. if nextAvailable (including waitTime) is 08:33:56, it will be rounded up to 08:45:00.
  // The following block will round down the time to the nearest 15 minute interval. So if
  // nextAvailable was 08:45:00, we first removed the waitTime in the code above (08:45:00 -> 08:40:00),
  // now we need to round down to the nearest 15 minutes interval (08:40:00 -> 08:30:00)
  const venueReopensAt =
    nextAvailableMinusWaitTime != null
      ? nextAvailableMinusWaitTime
          .minus({
            minutes: nextAvailableMinusWaitTime.minute % 15,
          })
          .toString()
      : null

  const recoverCartOnError: () => void = useCallback(() => {
    composeCartError(error, {
      onStaleError: () => {
        setCart({
          items: [],
          orderMethod: OrderMethod.pickup,
          isCartLoading: true,
          bill: {
            subtotal: '0.00',
            totalAfterTax: '0.00',
            grandTotal: '0.00',
            totalTax: '0.00',
            isEstimated: false,
            isInclusive: false,
            serviceCharges: [],
          },
        })
      },
      onLoyaltyError: () => {
        setCart({
          ...initialCart,
          isCartLoading: true,
        })
      },
    })
  }, [initialCart, error])

  const handleUpdateMe = async ({
    cart: updateCartRequest,
    giftCardCode,
    guestInfo,
  }: {
    cart: Partial<CartUpdateRequest>
    giftCardCode?: string | null
    guestInfo?: GuestInformation
  }): Promise<void> => {
    if (venueXRefID == null) {
      return
    }
    setCart((previousState) => ({
      ...previousState,
      isCartLoading: true,
    }))
    const scheduledFor = cart.scheduledFor

    try {
      const { cart: updatedCart } = await updateMe(venueXRefID, {
        cart: {
          ...updateCartRequest,
          items: (updateCartRequest.items ?? []).map(mapItemsToCartRequest),
          orderMethod: updateCartRequest.orderMethod ?? orderMethod,
        },
        giftCardCode,
        guest: guestInfo,
      })
      setCart({ ...updatedCart, isCartLoading: false })

      const orderMethodChanged = updatedCart.orderMethod && updatedCart.orderMethod !== orderMethod
      const scheduledForChanged = scheduledFor !== updatedCart.scheduledFor
      if (orderMethodChanged || scheduledForChanged) {
        await router.replace(
          '/[orderMethod]/[venueXRefID]/menu',
          `/${(updatedCart.orderMethod ?? orderMethod).toLowerCase()}/${venueXRefID}/menu`,
          { scroll: false }
        )
      }
      closeModal()

      if (venue) {
        // Set data for GTM
        window.dataLayer?.push({
          event: 'session',
          ...constructGTMSession(venue, operationalMenu, profile, guest, updatedCart),
        })
      }
    } catch (updateCartError: unknown) {
      const { code } = parseRequestError(updateCartError)
      composeCartError(updateCartError, {
        onLoyaltyError: () => {
          // lty reward, and cart item errors can trigger a change in the backend
          // so we should trigger a router.replace to get the latest the server data
          setModalError(code)
          router.replace(router.asPath, undefined, {
            scroll: false,
          })
        },
        onStaleError: () => {
          setModalError(StaleCartErrorCode.MODIFIER_GENERIC)
          router.replace(router.asPath, undefined, {
            scroll: false,
          })
        },
        onOrderingAddressError: () => {
          setModalError(code, async () => {
            if (venue) {
              openModal(
                <OrderMethodSelector
                  menuSource={menuSource}
                  venue={venue}
                  savedAddresses={deliveryAddresses}
                  cart={cart}
                  onUpdateMe={handleUpdateMe}
                />,
                {
                  forceSubmit: true,
                  scrollBehavior: 'SCROLL',
                  noBottomPadding: true,
                }
              )
            }
          })
        },
        onDefaultFallback: () => {
          setModalError(code)
          setCart({
            ...initialCart,
            isCartLoading: false,
          })
        },
      })
    }
  }

  const handleClearCart = async (): Promise<void> => {
    await handleUpdateMe({
      cart: {
        items: [],
        orderMethod: cart.orderMethod,
        orderingAddress: cart.orderingAddress,
        scheduledFor: cart.scheduledFor,
      },
      ...(cart.bill.giftCardPayments != null &&
        cart.bill.giftCardPayments.length > 0 &&
        venueXRefID != null &&
        profile?.consumerXRefID != null && { giftCardCode: null }),
    })
    setUserRewards(null)
  }

  const debouncedUpdateCart = useDebounce(
    (newItems: OrderItem[]) =>
      handleUpdateMe({
        cart: {
          items: newItems,
          scheduledFor: cart.scheduledFor,
          orderingAddress: cart.orderingAddress,
          orderMethod: cart.orderMethod,
        },
      }),
    250
  )

  // debounced cart update to backend calls on every qty change
  const handleCartItemsChange = async (items: OrderItem[]): Promise<void> => {
    debouncedUpdateCart(items)
    setCart((previousState) => ({
      ...previousState,
      items,
    }))
  }

  const handleCheckout = async (): Promise<void> => {
    const isUserNotLoggedIn = userSession.isInitialized && !userSession.isSignedIn
    const isUserLoggedIn = userSession.isInitialized && userSession.isSignedIn

    const checkoutPath = `/${orderMethod.toLowerCase()}/${venueXRefID}/checkout`

    if (venue) {
      // Open the guest checkout modal when: 1.User is not signed in  2.Not select `Continue as Guest` option
      if (isUserNotLoggedIn && guest == null) {
        openModal(
          <GuestCheckoutOptionsModal
            name={venue.name}
            branding={venue.branding}
            guestCheckoutEnabled={venue.guestCheckoutEnabled}
            loyaltyEnabled={!!loyaltyConfig.config?.loyaltyEnabled}
            marketingEnabled={!!loyaltyConfig.config?.marketingEnabled}
            handleUpdateMe={(guestInfo) =>
              handleUpdateMe({
                guestInfo,
                cart: {
                  items: (cart.items ?? []).map(mapItemsToCartRequest),
                  scheduledFor: cart.scheduledFor,
                  orderingAddress: cart.orderingAddress,
                  orderMethod: cart.orderMethod,
                },
              })
            }
            orderMethod={orderMethod}
            venueXRefID={venueXRefID}
          />,
          {
            onClose: () => closeModal(),
            scrollBehavior: 'SCROLL',
          }
        )
      }
      if (isUserLoggedIn || guest != null) {
        await router.push('/[orderMethod]/[venueXRefID]/checkout', checkoutPath)
      }
      return
    }

    if (isUserNotLoggedIn) {
      setRedirectURLCookie({
        url: '/[orderMethod]/[venueXRefID]/checkout',
        as: checkoutPath,
      })
      router.push('/api/consumer-frontend/auth/azure-b2c/login?p=B2C_1A_signup_signin')
    }

    if (isUserLoggedIn) {
      await router.push('/[orderMethod]/[venueXRefID]/checkout', checkoutPath)
    }
  }

  // EFFECTS
  useEffect(() => {
    if (error != null) {
      recoverCartOnError()
    }
  }, [error, recoverCartOnError])

  useEffect(() => {
    setCart((previousCart) => ({
      ...previousCart,
      ...initialCart,
      isCartLoading: false,
    }))
  }, [initialCart])

  useEffect(() => {
    if (
      venueXRefID == null ||
      cart.isCartLoading ||
      venue == null ||
      // Don't show the selector if there are no methods available
      venue.venueOrderingMethods.length <= 0
    ) {
      return
    }

    const pickupMethod = venue.venueOrderingMethods.find(
      ({ orderMethod: method }) => method === OrderMethod.pickup
    )
    const deliveryMethod = venue.venueOrderingMethods.find(
      ({ orderMethod: method }) => method === OrderMethod.delivery
    )
    const currentMethod = venue.venueOrderingMethods.find(
      ({ orderMethod: method }) => method === cart.orderMethod
    )
    const hasPickupSelectedButNotAvailable =
      cart.orderMethod === OrderMethod.pickup && pickupMethod === undefined
    const hasDeliverySelectedButNotAvailable =
      cart.orderMethod === OrderMethod.delivery && deliveryMethod === undefined
    if (
      // We don't want to show the modal if the user has selected it already, unless the selection is unavailable.
      cart.orderMethod !== undefined &&
      !hasPickupSelectedButNotAvailable &&
      !hasDeliverySelectedButNotAvailable &&
      // We want to show the modal if the venue throttled, so that the user needs to select a new time
      (!currentMethod?.orderThrottlingLimitReached ||
        (currentMethod.orderThrottlingLimitReached &&
          (!currentMethod.isSchedulingAvailable ||
            (currentMethod.isSchedulingAvailable && cart.scheduledFor))))
    ) {
      return
    }

    openModal(
      <OrderMethodSelector
        menuSource={menuSource}
        venue={venue}
        savedAddresses={deliveryAddresses}
        cart={cart}
        onUpdateMe={handleUpdateMe}
      />,
      {
        forceSubmit: true,
        scrollBehavior: 'SCROLL',
        noBottomPadding: true,
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  if (venue == null) {
    return null
  }

  if (!userSession.isInitialized || cart == null) {
    return <LoadScreen />
  }

  // RENDERER
  return (
    <div className={menuTemplateStyles(theme)}>
      <DefaultThemeProvider>
        <Header
          variant={HeaderVariant.MENU}
          venueOrderingMethods={venue?.venueOrderingMethods}
          venueXRefID={venue.venueXRefID}
          cart={{
            orderingAddress: cart.orderingAddress,
            scheduledFor: cart.scheduledFor,
            delivery: cart.delivery,
            isCartLoading: cart.isCartLoading,
            orderMethod,
          }}
          userProfile={profile ?? undefined}
          openOrderMethodSelector={() => {
            openModal(
              <OrderMethodSelector
                menuSource={menuSource}
                venue={venue}
                savedAddresses={deliveryAddresses}
                cart={cart}
                onUpdateMe={handleUpdateMe}
              />,
              {
                scrollBehavior: 'SCROLL',
                noBottomPadding: true,
              }
            )
          }}
        />
      </DefaultThemeProvider>
      <OrderMethodBanner
        venueAddress={venue?.address}
        venueOrderingMethods={venue?.venueOrderingMethods}
        cart={{
          orderingAddress: cart.orderingAddress,
          scheduledFor: cart.scheduledFor,
          delivery: cart.delivery,
          isCartLoading: cart.isCartLoading,
          orderMethod,
        }}
        openOrderMethodSelector={() => {
          openModal(
            <OrderMethodSelector
              menuSource={menuSource}
              venue={venue}
              savedAddresses={deliveryAddresses}
              cart={cart}
              onUpdateMe={handleUpdateMe}
            />,
            {
              scrollBehavior: 'SCROLL',
              noBottomPadding: true,
            }
          )
        }}
      />
      {venue.branding.bannerUrl && <VenueBanner venueBannerUrl={venue.branding.bannerUrl} />}
      <main className={menuPageBodyLayout(theme)} data-test="menu-page">
        <EditItemProvider operationalMenu={operationalMenu}>
          <section className={venueSectionStyles} data-test="venue-summary">
            <VenueSummary
              venue={{
                venueOrderingMethods: venue.venueOrderingMethods,
                name: venue.name,
                phone: venue.phone,
                address: venue.address,
                branding: venue.branding,
                venueXRefID: venue.venueXRefID,
              }}
              cart={{
                isCartLoading: cart.isCartLoading,
                scheduledFor: cart.scheduledFor,
                delivery: cart.delivery,
                bill: {
                  serviceCharges: cart.bill.serviceCharges,
                },
                orderMethod,
              }}
              availability={{
                isASAPAvailable,
                isSchedulingAvailable,
                venueReopensAt,
              }}
              loyaltyConfig={loyaltyConfig}
              isSignedIn={userSession.isSignedIn}
            />
          </section>
          <section className={menuSectionStyles}>
            <MenuList
              menuSource={menuSource}
              operationalMenu={operationalMenu}
              calorieStatement={venue.calorieStatement}
              sortedMenuPages={sortedMenuPages}
            />
          </section>
          {windowSize.width > theme.breakpointValues.SM ? (
            <section className={cartWidgetSectionStyles(theme)}>
              <CartWidget
                hasBoxShadow={windowSize.width > theme.breakpointValues.MD}
                cart={{
                  bill: cart.bill,
                  delivery: cart.delivery,
                  isCartLoading: cart.isCartLoading,
                  scheduledFor: cart.scheduledFor,
                  items: cart.items,
                  appliedLoyaltyRewardName: cart.loyaltyRewardApplied?.name,
                  orderMethod,
                  discountedOrderItemID: cart.discountedOrderItemID,
                }}
                variant={CartWidgetVariant.MENU}
                offersLoyaltyRewards={false}
                onItemsChange={handleCartItemsChange}
                userRewards={userRewards}
                onClearLoyalty={() => setUserRewards(null)}
                onClearCart={handleClearCart}
                onSubmit={handleCheckout}
                loyaltyConfig={loyaltyConfig}
                operationalMenu={operationalMenu}
                venue={{
                  isASAPAvailable,
                  isSchedulingAvailable,
                  venueReopensAt,
                  active: venue.active,
                  venueOrderingMethods: venue.venueOrderingMethods,
                  name: venue.name,
                  venueXRefID: venue.venueXRefID,
                }}
              />
            </section>
          ) : (
            <CartDrawer
              variant={CartWidgetVariant.MENU}
              cart={{
                bill: cart.bill,
                delivery: cart.delivery,
                isCartLoading: cart.isCartLoading,
                scheduledFor: cart.scheduledFor,
                appliedLoyaltyRewardName: cart.loyaltyRewardApplied?.name,
                items: cart.items,
                orderMethod,
              }}
              onItemsChange={handleCartItemsChange}
              venue={{
                isASAPAvailable,
                isSchedulingAvailable,
                venueReopensAt,
                active: venue.active,
                venueOrderingMethods: venue.venueOrderingMethods,
                name: venue.name,
                venueXRefID,
              }}
              userRewards={userRewards}
              onClearLoyalty={() => setUserRewards(null)}
              offersLoyaltyRewards={false}
              onCheckout={handleCheckout}
              onClearCart={handleClearCart}
              loyaltyConfig={loyaltyConfig}
              operationalMenu={operationalMenu}
            />
          )}

          <EditItemModal
            operationalMenu={operationalMenu}
            venue={venue}
            isASAPAvailable={isASAPAvailable}
            isSchedulingAvailable={isSchedulingAvailable}
            isActive={venue.active}
            onUpdateMe={handleUpdateMe}
            cart={cart}
          />
        </EditItemProvider>
      </main>
      <DefaultThemeProvider>
        <Footer />
      </DefaultThemeProvider>
    </div>
  )
}

export default MenuPage
