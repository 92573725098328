import type { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguages } from '@/libs/helpers/formatters'
import { useUserLanguage } from '@/providers/userLanguage'
import { Select, SelectOrientation, SvgIcon } from '@/tbui'

const LanguageSelector: FunctionComponent = () => {
  const { userLanguage, setUserLanguage } = useUserLanguage()
  const { t } = useTranslation()

  const options = Object.values(SupportedLanguages).map((lng) => ({
    value: lng,
    key: `supported-lang-option-${lng}`,
    label: t('language_selector.supported_language', {
      context: lng,
    }),
  }))

  const handleChange = (value: string): void => {
    setUserLanguage(value as SupportedLanguages)
  }

  return (
    <Select
      orientation={SelectOrientation.TOP}
      onChange={handleChange}
      options={options}
      currentLabel={
        <>
          <SvgIcon fontSize="16px" name="globe" />
          {t('language_selector.supported_language', {
            context: userLanguage,
          })}
        </>
      }
    />
  )
}

export default LanguageSelector
