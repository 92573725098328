import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import publicConfig from '@/config/public'
import { buildAssetsPath } from '@/libs/helpers/assets'
import { DefaultThemeProvider, Grid, SvgIcon, Text } from '@/tbui'
import { textStyles } from '../../tbui/Text/styles'

const StyledRow = styled(Grid)`
  gap: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
  }
  background-color: ${(props) => props.theme.palette.SECONDARY};
  border-radius: 12px;
  height: 90px;
  display: flex;
`

const StyledContentRow = styled(Grid)`
  display: flex;
  justify-content: space-between;
`

const StyledTitleText = styled(Text)`
  color: ${(props) => props.theme.colors.NAVY};
`

const StyledDescriptionText = styled.p`
  color: ${(props) => props.theme.colors.NAVY};
  ${({ theme }) => textStyles(theme).base.p}
  margin: 0.5rem 0 0 0;
  display: block;
`

const StyledImg = styled.img<{ isOrderPage?: boolean }>`
  border-bottom-right-radius: 28px;
  border-top-right-radius: 28px;
  height: 90px;

  ${(props) =>
    !props.isOrderPage
      ? `@media (min-width: ${props.theme.breakpoints.MD}) {
    content: url(${buildAssetsPath('/images/tb-gift-card-mockup.png')});
    border-bottom-left-radius: 3% 98%;
    border-top-left-radius: 3% 98%;
  }
  @media (
    max-width: ${props.theme.breakpoints.MD}) {
    content: url(${buildAssetsPath('/images/tb-gift-card-mockup-small.png')});
    border-bottom-left-radius: 49% 104%;
    border-top-left-radius: 56% 127%;
  }

  @media (max-width: ${props.theme.breakpoints.LG}) and (min-width: ${props.theme.breakpoints.MD}) {
    width: 60%;
  }`
      : `content: url(${buildAssetsPath('/images/tb-gift-card-mockup-small.png')});
      border-bottom-left-radius: 49% 104%;
      border-top-left-radius: 56% 127%;
  `};
`

const StyledSvgIcon = styled(SvgIcon)`
  display: inline-flex;
  vertical-align: middle;
  margin-left: 0.5rem;
`

const StyledAnchor = styled.a`
  text-decoration: none;
  display: block;
  margin-top: 10px;
`

interface DGCBannerProps {
  venueName: string
  loyaltyRestaurantGroupID: number
  isSignedIn: boolean
  isOrderPage?: boolean
}

const DGCBanner: FunctionComponent<DGCBannerProps> = ({
  venueName,
  loyaltyRestaurantGroupID,
  isSignedIn,
  isOrderPage,
}) => {
  // HOOKS
  const { t } = useTranslation()
  const { cwaURL } = publicConfig
  return (
    <DefaultThemeProvider>
      <StyledAnchor
        target="_blank"
        href={
          isSignedIn
            ? `${cwaURL}/${loyaltyRestaurantGroupID}/home/giftcards/create-card?autoLogin=true`
            : `${cwaURL}/${loyaltyRestaurantGroupID}/home/giftcards`
        }
        data-test="dgc-banner"
      >
        <StyledRow align="center">
          <StyledContentRow gap="8px" m="0 0 0 36px" align="center">
            <div>
              <StyledTitleText type="H3" data-test="dgc-banner-title">
                {t('venue.summary.banner.title')}
              </StyledTitleText>
              <StyledDescriptionText data-test="dgc-banner-subtitle">
                {t('venue.summary.banner.subtitle', { restaurant: venueName })}
                <StyledSvgIcon name="arrow" color="PRIMARY" fontSize="15px" />
              </StyledDescriptionText>
            </div>
            <StyledImg isOrderPage={isOrderPage} alt="tb-gift-card-mockup" />
          </StyledContentRow>
        </StyledRow>
      </StyledAnchor>
    </DefaultThemeProvider>
  )
}

export default DGCBanner
