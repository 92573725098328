import { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import CheckoutAsGuestModal from '@/components/GuestCheckout/CheckoutAsGuestModal'
import GuestCheckoutFooter from '@/components/GuestCheckout/GuestCheckoutFooter'
import GuestCheckoutHeader from '@/components/GuestCheckout/GuestCheckoutHeader'
import { Venue } from '@/libs/helpers/adapters'
import { GuestInformation } from '@/libs/helpers/apiClient'
import { useCookies } from '@/libs/helpers/hooks/useCookies'
import { OrderMethod } from '@/libs/helpers/utils'
import { useModal } from '@/providers/modal'
import { Button, Grid, SvgIcon, Text } from '@/tbui'

interface GuestCheckoutOptionsModalProps {
  name: Venue['name']
  branding: {
    logoUrl: Venue['branding']['logoUrl']
  }
  guestCheckoutEnabled: Venue['guestCheckoutEnabled']
  loyaltyEnabled: boolean
  marketingEnabled: boolean
  handleUpdateMe: (guest: GuestInformation) => Promise<void>
  venueXRefID: string
  orderMethod: OrderMethod
}

const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.TEXT_2};
  margin-top: 4px;
`

const GuestCheckoutOptionsModal: FunctionComponent<GuestCheckoutOptionsModalProps> = ({
  name,
  guestCheckoutEnabled,
  branding,
  loyaltyEnabled,
  marketingEnabled,
  handleUpdateMe,
  orderMethod,
  venueXRefID,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { setRedirectURLCookie } = useCookies()
  const { closeModal, openModal } = useModal()
  const checkoutPath = `/${orderMethod.toLowerCase()}/${venueXRefID}/checkout`

  const signIn = async (): Promise<void> => {
    setRedirectURLCookie({
      url: '/[orderMethod]/[venueXRefID]/checkout',
      as: checkoutPath,
    })
    router.push('/api/consumer-frontend/auth/azure-b2c/login?p=B2C_1A_signup_signin')
  }

  const signUp = async (): Promise<void> => {
    setRedirectURLCookie({
      url: '/[orderMethod]/[venueXRefID]/checkout',
      as: checkoutPath,
    })
    router.push(
      '/api/consumer-frontend/auth/azure-b2c/login?p=B2C_1A_signup_signin&signup_signin_option=signup'
    )
  }

  const continueAsGuest = (): void => {
    closeModal()
    openModal(
      <CheckoutAsGuestModal
        name={name}
        branding={branding}
        handleUpdateMe={handleUpdateMe}
        venueXRefID={venueXRefID}
        orderMethod={orderMethod}
      />,
      {
        onClose: () => closeModal(),
        scrollBehavior: 'SCROLL',
      }
    )
  }
  return (
    <Grid gap="1.75rem" data-test="guest-checkout-container">
      <GuestCheckoutHeader name={name} branding={branding} />
      <Grid gap="1rem">
        <StyledText data-test="guest-checkout-description">
          {t('guest_checkout.description', { name: name })}
        </StyledText>
        {guestCheckoutEnabled && (
          <Grid gap="0.5rem" data-test="guest-checkout-loyalty-section">
            <Text type="H3">{t('tbdine.sign_in')}</Text>
            <Grid container justify="start" align="center" template={'auto auto'} gap={'6px'}>
              <SvgIcon
                name="check_circle"
                color="SUCCESS"
                stroke="SUCCESS"
                fontSize="1.4rem"
              ></SvgIcon>
              <StyledText data-test="guest-checkout-loyalty-section-normal">
                {t('guest_checkout.saved_delivery_addresses')}
              </StyledText>
            </Grid>
            {loyaltyEnabled && (
              <Grid container justify="start" align="center" template={'auto auto'} gap={'6px'}>
                <SvgIcon
                  name="check_circle"
                  color="SUCCESS"
                  stroke="SUCCESS"
                  fontSize="1.4rem"
                ></SvgIcon>
                <StyledText data-test="guest-checkout-loyalty-section-loyalty-enabled">
                  {t('guest_checkout.automatic_enrolment')}
                </StyledText>
              </Grid>
            )}
            {marketingEnabled && (
              <Grid container justify="start" align="center" template={'auto auto'} gap={'6px'}>
                <SvgIcon
                  name="check_circle"
                  color="SUCCESS"
                  stroke="SUCCESS"
                  fontSize="1.4rem"
                ></SvgIcon>
                <StyledText data-test="guest-checkout-loyalty-section-marketing-enabled">
                  {t('guest_checkout.special_offers')}
                </StyledText>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container gap={guestCheckoutEnabled ? '1rem' : '2rem'}>
          <Button
            variant="PRIMARY"
            onClick={() => signIn()}
            type="button"
            data-test="guest-checkout-sign-in-btn"
          >
            {t('tbdine.sign_in')}
          </Button>
        </Grid>
        <Button
          variant="OUTLINE"
          onClick={() => signUp()}
          type="button"
          data-test="guest-checkout-create-account-btn"
        >
          {t('tbdine.create_account')}
        </Button>
      </Grid>
      <Grid gap="1rem">
        {guestCheckoutEnabled && (
          <Grid container gap="0.5rem">
            <Text type="H3">{t('guest_checkout.checkout_as_guest')}</Text>
            <Button
              variant="OUTLINE"
              onClick={() => continueAsGuest()}
              type="button"
              data-test="guest-checkout-continue-as-guest-btn"
            >
              {t('guest_checkout.continue_as_guest')}
            </Button>
          </Grid>
        )}
        <GuestCheckoutFooter />
      </Grid>
    </Grid>
  )
}

export default GuestCheckoutOptionsModal
