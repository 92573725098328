import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import type { OnlineOperationalMenuGroup } from '@/libs/helpers/adapters'

const MenuGroups = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 6px;
  position: absolute;
  top: 50px;
  left: 55px;
  width: 225px;
  max-height: 400px;
  background: ${({ theme }) => theme.palette.WHITE};
  border: 1px solid ${({ theme }) => theme.palette.GRAY_1};
  box-shadow: 0px 2px 4px ${({ theme }) => theme.palette.SHADOW};
  border-radius: 10px;
  list-style-type: none;
  z-index: 9999;
  overflow-y: auto;
`

const MenuGroupItem = styled.li<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.PRIMARY : theme.palette.SECONDARY};
  border-radius: ${({ theme }) => theme.shape.RADIUS};
  font-family: ${({ isSelected, theme }) =>
    isSelected ? theme.font.FAMILY_BOLD : theme.font.FAMILY};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.TEXT_PRIMARY : theme.palette.TEXT_SECONDARY};
  cursor: pointer;
  word-break: break-word;
`

interface IMenuGroupPopover {
  operationalMenuGroups: OnlineOperationalMenuGroup[]
  selectedMenuGroupID: string | null
  handleSelected: (id: string) => void
}

export const MenuGroupPopover: FunctionComponent<IMenuGroupPopover> = ({
  operationalMenuGroups,
  selectedMenuGroupID,
  handleSelected,
}) => {
  return (
    <MenuGroups>
      {operationalMenuGroups.map((menuGroup, index) => {
        const { label, XRefID } = menuGroup
        const key = `${XRefID}-${index}`
        const isSelected = XRefID === selectedMenuGroupID
        return (
          <MenuGroupItem
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            onClick={() => handleSelected(XRefID)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSelected(XRefID)
              }
            }}
            isSelected={isSelected}
          >
            {label}
          </MenuGroupItem>
        )
      })}
    </MenuGroups>
  )
}
