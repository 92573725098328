import { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import type { Delivery, VenueOrderingMethod } from '@/libs/helpers/adapters'
import { OrderMethod, getOrderMethodSettings } from '@/libs/helpers/utils'
import type { OrderingAddress } from '@/providers/googleMaps'
import { Grid, SvgIcon, Text } from '@/tbui'

interface OrderMethodBannerProps {
  venueAddress: string
  venueOrderingMethods: VenueOrderingMethod[]
  openOrderMethodSelector: () => void
  cart: {
    orderingAddress?: OrderingAddress
    scheduledFor?: string
    delivery?: Delivery
    isCartLoading: boolean
    orderMethod: OrderMethod
  }
}

const StyledBanner = styled(Grid)<{ isClickable: boolean }>`
  background-color: ${(props) => props.theme.palette.SECONDARY};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  padding: 16px;
  grid-area: orderMethod;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.MD}) {
    display: none;
  }
`

const OrderMethodBanner: FunctionComponent<OrderMethodBannerProps> = ({
  venueAddress = '',
  venueOrderingMethods = [],
  cart,
  openOrderMethodSelector,
}) => {
  const { t } = useTranslation()
  const orderMethod = cart.orderMethod
  const isDelivery = orderMethod === OrderMethod.delivery
  const { isASAPAvailable, isSchedulingAvailable } = getOrderMethodSettings(
    venueOrderingMethods,
    orderMethod,
    cart.delivery
  )
  const isMethodAvailable = isASAPAvailable || isSchedulingAvailable

  // banner is clickable when pickup scheduling only,
  // delivery is the current order method,
  // pickup and delivery are available
  // and the given method is available (i.e ASAP or scheduling)
  const isClickable =
    (venueOrderingMethods.length > 1 || isDelivery || isSchedulingAvailable) && isMethodAvailable

  if (
    !isMethodAvailable ||
    (isDelivery && cart.orderingAddress == null) ||
    (!isASAPAvailable && !isSchedulingAvailable)
  ) {
    return null
  }

  // show user address when delivery, but show venue address when pickup
  const address = isDelivery ? cart.orderingAddress?.formattedAddress : venueAddress
  const translationVars = { context: orderMethod.toLowerCase(), address }

  return (
    <StyledBanner
      isClickable={isClickable}
      onClick={isClickable ? openOrderMethodSelector : undefined}
      template="auto 1fr"
    >
      <SvgIcon color="TEXT_SECONDARY" fontSize="20px" name="location" />
      <Text p="6px 8px" color="TEXT_SECONDARY" bold>
        {cart.scheduledFor != null && isSchedulingAvailable
          ? t('order_method_selector.banner_scheduled', {
              ...translationVars,
              scheduledFor: `${t('date', {
                value: DateTime.fromISO(cart.scheduledFor),
              })} - ${t('time', {
                value: DateTime.fromISO(cart.scheduledFor),
              })}`,
            })
          : t('order_method_selector.banner', translationVars)}
      </Text>
    </StyledBanner>
  )
}

export default OrderMethodBanner
