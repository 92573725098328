import { FunctionComponent, useEffect, useState, ReactNode } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import type { LoyaltyReward } from '@/libs/helpers/apiClient'
import { useModal } from '@/providers/modal'
import { SvgIcon, Text, Button, Grid } from '@/tbui'

const ModalContent = styled(Grid)`
  padding-top: 0;
  ${(props) =>
    props.theme.mixin.fluidRange(
      {
        prop: 'paddingRight',
        fromSize: '24px',
        toSize: '48px',
      },
      {
        prop: 'paddingLeft',
        fromSize: '24px',
        toSize: '48px',
      }
    )};
`

const ModalContentHeader = styled.div`
  > h2 {
    margin-bottom: 0.25rem;
  }
  margin-bottom: 2rem;
`

const RewardsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const Reward = styled.li`
  :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

const RewardTitle = styled(Text)`
  margin: 0;
  margin-bottom: 0.25rem;
`

const RewardDescription = styled(Text)`
  color: ${(props) => props.theme.palette.TEXT_2};
`

const RewardsListLink = styled(Button)`
  display: flex;
  align-items: center;
  p,
  i {
    color: currentColor;
  }
`

export const LoyaltyRewards: FunctionComponent<{
  venueName: string
  rewards?: LoyaltyReward[]
}> = ({ venueName, rewards = [] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { openModal, closeModal } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    const renderModalContent = (): ReactNode => {
      return (
        <ModalContent p="24px" data-test="venue-rewards-modal">
          <ModalContentHeader>
            <Text type="H2">{`${venueName} ${t('venue.loyalty.rewards_title')}`}</Text>
            <Text type="P" fontSize="18px">
              {t('venue.loyalty.rewards_instruction')}
            </Text>
          </ModalContentHeader>
          <RewardsList>
            {rewards.map((reward) => (
              <Reward key={reward.rewardXRefID}>
                <RewardTitle type="H3" fontSize="16px">
                  {reward.name} - {`${reward.points} points`}
                </RewardTitle>
                <RewardDescription>{reward.description}</RewardDescription>
              </Reward>
            ))}
          </RewardsList>
        </ModalContent>
      )
    }

    if (isOpen) {
      openModal(renderModalContent(), {
        variant: 'DEFAULT',
        scrollBehavior: 'SCROLL',
        onClose: (): void => {
          setIsOpen(false)
          closeModal()
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, rewards, venueName])

  return (
    <RewardsListLink
      data-test="loyalty-rewards-link"
      variant="LINK"
      type="button"
      onClick={() => {
        setIsOpen(true)
      }}
    >
      <SvgIcon name="heart" />
      &nbsp;
      <Text bold color="PRIMARY">
        {t('venue.loyalty.rewards_title')}
      </Text>
    </RewardsListLink>
  )
}
