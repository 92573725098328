/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect, forwardRef, ReactNode, MouseEventHandler } from 'react'
import { css } from '@emotion/css'
import smoothscroll from 'smoothscroll-polyfill'

interface ScrollTrackProps {
  children: ReactNode
  selected: string
}
interface ScrollTrackState {
  state: {
    isScrolling: boolean
    clientX: number
    scrollX: number
  }
  onMouseDown: MouseEventHandler<HTMLDivElement>
  onMouseMove: MouseEventHandler<HTMLDivElement>
}

const scrollTrackStyles = css`
  display: flex;
  gap: 12px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`

// eslint-disable-next-line react/display-name
const ScrollTrack = forwardRef<HTMLDivElement, ScrollTrackProps>((props, ref) => {
  const { selected } = props

  const useScrollTrack = (elementRef: React.ForwardedRef<HTMLDivElement>): ScrollTrackState => {
    const [state, setState] = useState<ScrollTrackState['state']>({
      isScrolling: false,
      clientX: 0,
      scrollX: 0,
    })
    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
      setState({ ...state, isScrolling: true, clientX: e.clientX })
    }
    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
      const { clientX } = state
      // this if is to satisfy strict:true option
      if (typeof elementRef === 'function') {
        return
      }
      if (state.isScrolling && elementRef?.current != null) {
        elementRef.current.scrollLeft = elementRef.current.scrollLeft - e.clientX + clientX
        state.scrollX = elementRef.current.scrollLeft - e.clientX + clientX
        state.clientX = e.clientX
      }
    }
    useEffect(() => {
      const onMouseUp = (): void => {
        setState({ ...state, isScrolling: false })
      }
      window.addEventListener('mouseup', onMouseUp)
      return () => window.removeEventListener('mouseup', onMouseUp)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      state,
      onMouseDown,
      onMouseMove,
    }
  }

  useEffect(() => {
    const selectedElement = document.getElementById(`tab-${selected}`)
    const scrollBar = document.getElementById('scroll-track')

    if (selectedElement != null && scrollBar?.scrollTo != null) {
      scrollBar.scrollTo({
        left: selectedElement.getBoundingClientRect().left - scrollBar.getBoundingClientRect().left,
        behavior: 'smooth',
      })
    }
  }, [selected])

  const { onMouseMove, onMouseDown } = useScrollTrack(ref)
  // polyfill for browsers w/out native smooth scroll
  smoothscroll.polyfill()

  return (
    <div
      id="scroll-track"
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      className={scrollTrackStyles}
    >
      {props.children}
    </div>
  )
})

export default ScrollTrack
