import { FunctionComponent, useRef } from 'react'
import styled from '@emotion/styled'
import ScrollTrack from '@/components/ScrollTrack'
import { useButtonScrolling } from '@/components/ScrollTrack/useButtonScroll'
import type { OnlineOperationalMenuGroup } from '@/libs/helpers/adapters'
import { SvgIcon } from '@/tbui'

const slidePadding = 6
const percentageToSlide = 0.85
const hideArrowWidth = '450px'

const CarouselWrap = styled.div`
  position: relative;
  padding-left: ${slidePadding}px;
  padding-right: ${slidePadding * 2}px;
  @media (max-width: ${hideArrowWidth}) {
    padding-left: 0;
    padding-right: 0;
  }
`
const Button = styled.button<{ direction: string }>`
  position: absolute;
  z-index: 999;
  border: none;
  padding: 0;
  background-color: transparent;
  height: 100%;
  i {
    display: flex;
    align-items: center;
  }
  @media (max-width: ${hideArrowWidth}) {
    display: none;
  }
  top: 0;
  ${(props) =>
    props.direction === 'left' && {
      left: '-12px',
    }}
  ${(props) =>
    props.direction === 'right' && {
      right: '-10px',
    }}
`
const CarouselSlide = styled.div`
  display: inline-block;
  gap: ${slidePadding * 2}px;
`
const Tab = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding: 12px 18px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.PRIMARY : theme.palette.SECONDARY};
  border: none;
  border-radius: ${({ theme }) => theme.shape.RADIUS};
  font-family: ${({ isSelected, theme }) =>
    isSelected ? theme.font.FAMILY_BOLD : theme.font.FAMILY};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.TEXT_PRIMARY : theme.palette.TEXT_SECONDARY};
  margin: none;
  font-size: inherit;
  // indicate focus on a button for keyboard navigation
  :focus {
    outline: 1px solid ${({ theme }) => theme.palette.TEXT_PRIMARY};
  }
  @media (max-width: ${hideArrowWidth}) {
    padding: 8px 8px;
  }
`

interface ITabCarousel {
  handleSelected: (id: string) => void
  selected: string
  operationalMenuGroups: OnlineOperationalMenuGroup[]
}

export const TabCarousel: FunctionComponent<ITabCarousel> = ({
  handleSelected,
  selected,
  operationalMenuGroups,
}) => {
  const scrollTrackRef = useRef<HTMLDivElement | null>(null)
  const buttonScroll = useButtonScrolling(scrollTrackRef)
  const { width, scrollX, scrollEnd, scrollByAmount } = buttonScroll

  return (
    <CarouselWrap>
      <Button
        direction="left"
        type="button"
        onClick={() => scrollByAmount(-width * percentageToSlide)}
        css={{
          transform: 'rotate(-180deg)',
          display: scrollX < 10 ? 'none' : 'block',
        }}
      >
        <SvgIcon name="chevron_right" fontSize="18px" color="PRIMARY" />
      </Button>
      <ScrollTrack selected={selected} ref={scrollTrackRef}>
        {operationalMenuGroups.map(({ label, XRefID }) => {
          const isSelected = XRefID === selected
          return (
            <CarouselSlide
              key={`tab-${XRefID}`}
              data-test={`menu-group-tab-${XRefID}`}
              role="presentation"
            >
              <Tab
                role="tab"
                id={`tab-${XRefID}`}
                aria-selected={isSelected}
                aria-controls={`panel-${XRefID}`}
                onClick={() => handleSelected(XRefID)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSelected(XRefID)
                  }
                }}
                isSelected={isSelected}
                type="button"
              >
                {label}
              </Tab>
            </CarouselSlide>
          )
        })}
      </ScrollTrack>
      <Button
        direction="right"
        type="button"
        css={{ display: scrollEnd ? 'none' : 'block ' }}
        onClick={() => scrollByAmount(width * percentageToSlide)}
      >
        <SvgIcon name="chevron_right" fontSize="18px" color="PRIMARY" />
      </Button>
    </CarouselWrap>
  )
}
